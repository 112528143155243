import React from "react"
import AuthLayout from "../../components/layout/auth-layout"
import SignUp from "../../components/sign-up/sign-up"
import { GlobalAuthContext } from "../../context/authContext"
import { navigate } from "gatsby"
import Spinner from "../../components/spinner/spinner"

export default function SignupPage({ location }) {
  const { state } = React.useContext(GlobalAuthContext)
  const [isLoading, setLoading] = React.useState(true)

  React.useEffect(() => {
    const checkAuthentication = async () => {
      if (state.isAuthenticated) {
        if (state?.user?.candidate?.id) {
          navigate("/candidate/profile/dashboard")
        } else {
          navigate("/company/profile/dashboard")
        }
      }
    }
    checkAuthentication()
    setLoading(state.isAuthenticated)
  }, [state.isAuthenticated])

  if (isLoading) {
    return <Spinner />
  }
  return (
    <AuthLayout
      location={location}
      slug={"signup"}
      currentPageName={"Sign Up"}
      currentPageLink={"/signup"}
    >
      <SignUp
        location={location}
        data={{
          title: "Sign Up",
          description:
            "<p>Quickly register your account in a few steps to get started.</p>",
        }}
      />
    </AuthLayout>
  )
}
