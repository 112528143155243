const user_type = {
  CANDIDATE: 2,
  COMPANY: 3,
}

export const USER = {
  1: "General",
  2: "Candidate",
  3: "Company",
}

export const SUBJECT_CONTACT = {
  1: "Feedback",
  2: "Suggestion",
}

export default user_type
