import React, { useState, useContext, useEffect } from "react"

import { Link, navigate } from "gatsby"
import { Container, Row, Col, Button } from "react-bootstrap"

import { GlobalAuthContext } from "../../context/authContext"

import endpoints from "../../constants/endpoints"
import queryString from "query-string"

import user_type from "../../constants/user-types"
import { WDN } from "../../services/axiosInstance"

import Warning from "../../images/icons/wdn-warning.png"

import "./signup.scss"
import InputTextBox from "../input/input-text-box"
import renderElement from "../../utils/renderElement"

const SignUp = props => {
  const query = queryString.parse(props?.location?.search)

  const [error, setError] = useState({})
  const [email, setEmail] = useState("")
  const [userType, setUserType] = useState(parseInt(query.userType ?? 0))
  const [password, setPassword] = useState("")
  const [isLoading, setLoading] = useState(false)

  const { state, dispatch } = useContext(GlobalAuthContext)

  const handleEmailInputChange = email => {
    setEmail(email)
  }

  const handlePasswordInputChange = password => {
    setPassword(password)
  }

  const handleCandidateButtonClick = e => {
    e.preventDefault()
    setUserType(user_type.CANDIDATE)
  }

  const handleCompanyButtonClick = e => {
    e.preventDefault()
    setUserType(user_type.COMPANY)
  }

  const handleSignUpFormSubmit = async e => {
    e.preventDefault()
    if (userType === "") {
      setError({ role_id: "Please Choose Candidate or Company" })
      return
    }
    if (email === "") {
      setError({ email: "Email may not be empty" })
      return
    }
    if (password === "") {
      setError({ password: "Password may not be empty" })
      return
    }
    setLoading(true)
    setError({})
    const data = {
      username: `user${Math.floor(Math.random() * 10000)}`,
      role_id: `${userType}`,
      email: email,
      password1: password,
      password2: password,
    }
    try {
      const res = await WDN.post(endpoints.SIGN_UP_ROUTE, data)
      if (res.status === 201) {
        await dispatch({
          type: "LOGIN_NOT_VERIFIED",
          payload: res.data.key,
        })
        navigate("/verify-email")
      }
    } catch (err) {
      setError(err.response.data)
    }
    setLoading(false)
  }

  const handleInputFocus = () => {
    setError({})
  }

  useEffect(() => {}, [state?.isAuthenticated])

  useEffect(() => {
    // if (query.userType === "2") setUserType(user_type.CANDIDATE)
    // else if (query.userType === "3") setUserType(user_type.COMPANY);
  }, [])

  return (
    <section className="sign-up-page">
      <Container>
        <Row>
          <Col lg="6 m-auto" md="10" sm="10">
            <div className="form-container">
              <div className="form-title">
                {props.data?.title && (
                  <h1
                    dangerouslySetInnerHTML={renderElement(props.data?.title)}
                  ></h1>
                )}
                {props.data?.description && (
                  <div
                    className="form-description"
                    dangerouslySetInnerHTML={renderElement(
                      props.data?.description
                    )}
                  ></div>
                )}
              </div>
              <Row className="justify-content-center">
                <div className="d-flex mb-4">
                  <Button
                    variant="custom mr-2 text-uppercase"
                    onClick={handleCandidateButtonClick}
                    className={
                      userType === user_type.CANDIDATE
                        ? "blue disabled"
                        : "faded"
                    }
                  >
                    Candidate
                  </Button>
                  <Button
                    variant="custom text-uppercase"
                    onClick={handleCompanyButtonClick}
                    className={
                      userType === user_type.COMPANY ? "blue disabled" : "faded"
                    }
                  >
                    Company
                  </Button>
                </div>
              </Row>
              <form onSubmit={handleSignUpFormSubmit}>
                <InputTextBox
                  name="email"
                  placeholder="example@email.com"
                  label="email"
                  onChange={handleEmailInputChange}
                  onFocus={handleInputFocus}
                  value={email}
                  class={error && error.email && `error`}
                  type="text"
                />

                <InputTextBox
                  name="password"
                  placeholder="Password"
                  label="password"
                  onFocus={handleInputFocus}
                  onChange={handlePasswordInputChange}
                  class={
                    error && (error.password1 || error.password) && `error`
                  }
                  value={password}
                  type="password"
                />
                <Row>
                  <Col>
                    {error &&
                      Object.keys(error)
                        .slice(0, 1)
                        .map((key, value) => {
                          return (
                            <div
                              key={value}
                              className="alert-block mb-4 d-flex align-items-center"
                            >
                              <img
                                src={Warning}
                                className="mr-3"
                                alt="wdn-warning"
                              />
                              {error[key]}
                            </div>
                          )
                        })}
                  </Col>
                </Row>
                <div className="form-group">
                  <input
                    type="submit"
                    disabled={isLoading}
                    className="btn-custom primary w-100 custom-border rounded TagManager___SubmitButton"
                    value={isLoading ? `Processing` : `Sign Up`}
                  />
                </div>
              </form>
              <div className="mb-4">
                <p>
                  Already have an account?<Link to="/login"> Login</Link>
                </p>
              </div>
              <div className=" border-top">
                <p className="mb-0">
                  By creating an account, you agree to our
                  <Link to="/tac"> Terms of Use </Link> and
                  <Link to="/privacy"> Privacy Policy</Link>.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default SignUp
