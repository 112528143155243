import React, { useContext } from "react"
import Layout from "./layout"
import BreadcrumbComponent from "../breadcrumb/breadcrum"
import SEO from "../seo"

import loginMeta from "../../images/loginMeta.jpg"
import signupMeta from "../../images/signupMeta.jpg"
import { GlobalAuthContext } from "../../context/authContext"
import { navigate } from "gatsby"

export default function AuthLayout({
  children,
  location,
  slug,
  currentPageName,
  currentPageLink,
}) {
  const isLoginPage = slug === "login"

  // const { state } = React.useContext(GlobalAuthContext)

  // React.useEffect(() => {
  //   console.log(state.isAuthenticated)
  //   if (state.isAuthenticated) {
  //     if (state?.user?.candidate?.id) {
  //       navigate("/candidate/profile/dashboard")
  //     } else {
  //       navigate("/company/profile/dashboard")
  //     }
  //   }
  // }, [state.isAuthenticated])

  return (
    <Layout noVerification={true} location={location} slug={slug}>
      <div className="front-page">
        <BreadcrumbComponent
          pages={[{ name: "Home", link: "/" }]}
          currentPage={{
            name: ` ${currentPageName}`,
            link: currentPageLink,
          }}
        />
        <SEO
          title={isLoginPage ? "Login - WDN" : "Signup - WDN"}
          description={
            isLoginPage
              ? "Are you as a Company seeking appropriate candidates for your organization? Or a Candidate willing to join a Company? Log in today."
              : "Sign up in WDN for free and find appropriate candidates and hiring companies at the common platform."
          }
          seoImage={isLoginPage ? loginMeta : signupMeta}
        />
        {children}
      </div>
    </Layout>
  )
}
